import { Container } from "../components/Container";
import React from "react";

const Index = () => {
  return (
    <Container edges>
      Ventact is a crazy-fun activity weekend for Rangers, Venture Scouts and
      Senior Branch. It’s held in Kilcully Campsite which is just north of the
      real capital! (Cork) Over the weekend there’s campfires, discos, random
      games and surprise activities around Cork City and County. Have a look at
      all of our photos and videos as well as reading up on the event. Ventact
      is unique as it is the only event of it’s type which is organised by a
      Joint Committee of Scouts and Guides and sees a gathering of 300-500 young
      people.
    </Container>
  );
};

export default Index;
